import React, { useState } from 'react';
import { Search, Truck, FileText, Code, FileSearch, PlaneTakeoff, MoreHorizontal, Loader, ChevronDown } from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';
import { formatShipmentResponse } from '../../utils/responseFormatter';
import AnalyticalResponse from '../AnalyticalResponse';
import CustomerAnalysis from '../CustomerAnalysis';
import CarrierAnalysis from '../CarrierAnalysis';
import SalesRepAnalysis from '../SalesRepAnalysis';
import AnalyticsChart from '../Analytics/AnalyticsChart';

const DocumentLink = ({ label, url }) => (
  <div className="flex items-start">
    <span className="text-gray-400 mr-2">–</span>
    <span>
      {label}:{' '}
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-400 hover:text-blue-300 underline"
      >
        View Document
      </a>
    </span>
  </div>
);

const QuickActionButton = ({ icon: Icon, label }) => (
  <button
    className="flex items-center gap-2 px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800/50 rounded-lg transition-colors duration-200"
  >
    <Icon className="w-4 h-4" />
    <span>{label}</span>
  </button>
);

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  // Add new state for tracking if we're showing results
  const [isShowingResults, setIsShowingResults] = useState(false);

  // Add this state at the component level
  const [expandedLanes, setExpandedLanes] = useState({});

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchTerm.trim()) return;
    
    setIsLoading(true);
    setError(null);
    setIsShowingResults(true);
    
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 29000); // Abort just before Heroku's 30s timeout

      const token = await getAccessTokenSilently({
        audience: 'https://one-stop-api',
        scope: 'openid profile email'
      });

      const extractedShipmentId = searchTerm.match(/\b\d{9}\b/)?.[0];
      console.log('Searching for shipment:', extractedShipmentId);

      const response = await fetch('/api/query', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: searchTerm,
          shipmentId: extractedShipmentId
        }),
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      const data = await response.json();
      console.log('Raw backend response:', JSON.stringify(data, null, 2));
      
      // Verify the response structure
      if (!data.type || !data.data) {
        console.error('Invalid response format:', data);
        setError('Received invalid response format from server');
        return;
      }

      console.log('Backend Response:', data);
      data.query = searchTerm;
      console.log('API Response:', data);
      console.log('Response content:', data.answer?.content);

      setResponse(data);
    } catch (error) {
      console.error('Search error:', error);
      if (error.name === 'AbortError') {
        setError('Request timed out. Please try a more specific query or contact support if the issue persists.');
      } else {
        setError('An error occurred while fetching the data');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderDetail = (detail) => {
    if (typeof detail === 'string') {
      return (
        <div className="flex items-start">
          <span className="text-gray-400 mr-2">–</span>
          <span>{detail}</span>
        </div>
      );
    }
    
    if (detail.type === 'documents') {
      return (
        <div className="flex items-start">
          <span className="text-gray-400 mr-2">–</span>
          <span>
            Documents: {detail.docs.map((doc, i) => (
              <span key={i}>
                {i > 0 && ', '}
                <a
                  href={doc.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-300 underline"
                >
                  {doc.type}
                </a>
              </span>
            ))}
          </span>
        </div>
      );
    }
    
    return null;
  };

  const renderShipmentResponse = (data) => {
    console.log('renderShipmentResponse data:', data);
    
    return (
      <div className="p-4 bg-gray-800/50 border border-gray-700 rounded-lg opacity-0 animate-fadeInUp">
        <div className="text-white space-y-4">
          <p className="text-gray-300">
            {data.statement}
          </p>
          {data.details && data.details.length > 0 && (
            <ul className="space-y-2">
              {data.details.map((detail, index) => (
                <li 
                  key={index}
                  className="opacity-0 animate-fadeInRight"
                  style={{ animationDelay: `${(index + 1) * 100}ms` }}
                >
                  {typeof detail === 'string' ? (
                    <div className="flex items-start">
                      <span className="text-gray-400 mr-2">-</span>
                      <span>{detail}</span>
                    </div>
                  ) : detail.type === 'documents' ? (
                    <div className="flex items-start">
                      <span className="text-gray-400 mr-2">-</span>
                      <span>
                        Documents: {detail.docs.map((doc, i) => (
                          <span key={i}>
                            {i > 0 && ', '}
                            <a
                              href={doc.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-400 hover:text-blue-300 underline"
                            >
                              {doc.type === 'POD' ? 'POD' : 
                               doc.type === 'Invoice' ? 'Invoice' : 
                               doc.type}
                            </a>
                          </span>
                        ))}
                      </span>
                    </div>
                  ) : null}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  };

  const renderGeneralResponse = (response) => {
    return (
      <div className="p-4 bg-gray-800/50 border border-gray-700 rounded-lg">
        <p className="text-white">
          {response.data?.statement || response.data?.content || response.message || 'No response available'}
        </p>
      </div>
    );
  };

  // Keep the existing renderLaneResponse but modify it
  const renderLaneResponse = (data) => {
    if (!data?.metrics || !Array.isArray(data.metrics)) {
      return (
        <div className="p-4 bg-gray-800/50 border border-gray-700 rounded-lg">
          <p className="text-white">No lane data available</p>
        </div>
      );
    }

    // Add the toggle function
    const toggleLane = (laneId) => {
      setExpandedLanes(prev => ({
        ...prev,
        [laneId]: !prev[laneId]
      }));
    };

    return (
      <div className="max-w-full overflow-hidden bg-gray-800/50 border border-gray-700 rounded-lg">
        {data.metrics.map((metric, index) => (
          <div key={index} className="border-b border-gray-700 last:border-0">
            {/* Existing lane summary - modified to be clickable */}
            <div 
              onClick={() => toggleLane(index)}
              className="p-4 hover:bg-gray-800/80 cursor-pointer"
            >
              <div className="flex justify-between items-center">
                <div className="text-lg font-medium text-white">
                  {metric.label}
                  <ChevronDown 
                    className={`inline-block ml-2 w-5 h-5 text-gray-400 transition-transform duration-200 
                      ${expandedLanes[index] ? 'rotate-180' : ''}`}
                  />
                </div>
                <div className="flex items-center gap-6">
                  <div className="text-right">
                    <span className="text-sm text-gray-400 mr-2">Revenue</span>
                    <span className="text-green-400">{metric.revenue}</span>
                  </div>
                  <div className="text-right">
                    <span className="text-sm text-gray-400 mr-2">Margin</span>
                    <span className="text-blue-400">{metric.margin}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Expandable section - only rendered if backend provides shipment details */}
            {expandedLanes[index] && metric.shipments_detail && (
              <div className="bg-gray-900/50 p-2">
                <div className="text-sm text-gray-400">
                  Shipment details coming soon...
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderSalesResponse = (data) => {
    return (
      <div className="max-w-full overflow-hidden p-4 bg-gray-800/50 border border-gray-700 rounded-lg">
        <h3 className="text-white text-lg font-semibold mb-6">{data.statement}</h3>
        <div className="space-y-3">
          {data.metrics.map((metric, index) => (
            <div 
              key={index} 
              className="p-4 bg-gray-800/80 rounded-lg border border-gray-700/50"
            >
              {/* Sales Rep Name */}
              <div className="flex justify-between items-baseline mb-4">
                <h4 className="text-lg font-medium text-white">
                  {metric.label}
                </h4>
                <span className="text-gray-400">
                  {metric.shipmentCount} shipments
                </span>
              </div>
              
              {/* Metrics Grid */}
              <div className="grid grid-cols-3 gap-4">
                {/* Revenue */}
                <div className="p-3 bg-gray-700/30 rounded-lg">
                  <div className="text-sm text-gray-400 mb-1">Revenue</div>
                  <div className="text-lg font-semibold text-green-400">
                    {metric.totalSell}
                  </div>
                </div>
                
                {/* Cost */}
                <div className="p-3 bg-gray-700/30 rounded-lg">
                  <div className="text-sm text-gray-400 mb-1">Cost</div>
                  <div className="text-lg font-semibold text-red-400">
                    {metric.totalBuy}
                  </div>
                </div>
                
                {/* Margin */}
                <div className="p-3 bg-gray-700/30 rounded-lg">
                  <div className="text-sm text-gray-400 mb-1">Margin</div>
                  <div className="text-lg font-semibold text-blue-400">
                    {metric.marginPercent}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderAnalyticsChart = (data) => {
    // Only render chart if data includes visualization specs
    if (!data?.visualization) return null;

    return (
      <AnalyticsChart 
        options={data.visualization}
        loading={false}
      />
    );
  };

  const renderResponse = (response) => {
    console.log('Rendering response:', response);
    console.log('Response type:', response?.type);
    console.log('Response data:', JSON.stringify(response?.data, null, 2));

    if (!response || !response.data) {
      return <div className="text-gray-400">No response available</div>;
    }

    // Convert type to lowercase for case-insensitive comparison
    const responseType = response.type?.toLowerCase();

    try {
      switch (responseType) {
        case 'shipping':
          return renderShipmentResponse(response.data);
        
        case 'lane':
          return (
            <div>
              {renderLaneResponse(response.data)}
              {renderAnalyticsChart(response.data)}
            </div>
          );
        
        case 'sales':
          return (
            <div className="space-y-4">
              <SalesRepAnalysis 
                data={{
                  title: response.data.title,
                  statement: response.data.statement,
                  reps: response.data.reps.map(rep => ({
                    salesRep: rep.salesRep || 'Unassigned',
                    totalRevenue: rep.totalRevenue,
                    profit: rep.profit,
                    margin: rep.margin,
                    shipmentCount: rep.shipmentCount,
                    // Add these fields for the expanded view
                    volume: rep.shipmentCount,
                    customerCount: rep.customerCount || '-',
                    onTimeRate: rep.onTimeRate || '-'
                  }))
                }} 
              />

              {response?.data?.visualization && (
                <div className="p-4 bg-gray-800/50 border border-gray-700 rounded-lg">
                  <AnalyticsChart 
                    options={response.data.visualization} 
                    loading={false}
                  />
                </div>
              )}
            </div>
          );
        
        case 'analytical':
        case 'general':
          return renderGeneralResponse(response.data);
        
        default:
          console.warn('Unknown response type:', responseType);
          return <div className="text-gray-400">Unknown response type</div>;
      }
    } catch (error) {
      console.error('Error rendering response:', error);
      return <div className="text-red-400">Error rendering response</div>;
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Search Container - centered vertically and horizontally */}
      <div className="flex-1 flex flex-col items-center justify-start pt-[20vh]">
        {/* Search Bar and Logo Container */}
        <div className="w-full max-w-3xl px-4">
          {/* Logo and search input section */}
          <div className="mb-4">
            {/* Title only shows when not showing results */}
            {!isShowingResults && (
              <h1 className="text-3xl font-bold text-white text-center mb-8">
                Ready to streamline your logistics with AI?
              </h1>
            )}
            
            {/* Search Form */}
            <form 
              onSubmit={handleSearch} 
              className={`relative flex items-center transition-all duration-500 ease-out ${
                isShowingResults ? 'transform translate-y-0 mb-4' : 'mb-4'
              }`}
            >
              <div className="absolute left-3 text-gray-400">
                <Search className="w-5 h-5" />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Ask about shipments, tracking, or analysis..."
                className="w-full py-3 pl-10 pr-4 bg-gray-800 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-700 transition-all duration-200"
              />
              <button
                type="submit"
                disabled={isLoading}
                className="absolute right-3 p-1 rounded-lg hover:bg-gray-700/50 text-gray-400 disabled:opacity-50"
              >
                {isLoading ? (
                  <Loader className="w-5 h-5 animate-spin" />
                ) : (
                  <svg className="w-5 h-5 rotate-180 transform" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
                  </svg>
                )}
              </button>
            </form>
          </div>

          {/* Quick Action Buttons */}
          <div className="flex flex-wrap gap-2 justify-center mt-4">
            <QuickActionButton icon={Truck} label="Track Shipment" />
            <QuickActionButton icon={FileText} label="Documentation" />
            <QuickActionButton icon={Code} label="API Access" />
            <QuickActionButton icon={FileSearch} label="Analytics" />
            <QuickActionButton icon={PlaneTakeoff} label="Quick Ship" />
            <QuickActionButton icon={MoreHorizontal} label="More" />
          </div>

          {/* Results Container - will push content down instead of up */}
          <div className="mt-6 w-full">
            {(error || response) && (
              <div className={`
                transition-all duration-500 ease-out
                ${isShowingResults 
                  ? 'opacity-100 translate-y-0' 
                  : 'opacity-0 translate-y-8 pointer-events-none'
                }
              `}>
                {error && (
                  <div className="p-4 bg-red-900/50 border border-red-700 rounded-lg">
                    <p className="text-red-400">{error}</p>
                  </div>
                )}
                
                {response && (
                  <div className="mt-4">
                    {renderResponse(response)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
