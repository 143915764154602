import React, { useState } from 'react';
import { ChevronDown, ChevronUp, UserCircle } from 'lucide-react';

const SalesRepAnalysis = ({ data }) => {
  const [expandedRep, setExpandedRep] = useState(null);
  
  if (!data || !data.reps) {
    return <div className="text-gray-400">No sales data available</div>;
  }

  const { title, statement, reps } = data;

  return (
    <div className="w-full bg-gray-800/50 border border-gray-700 rounded-lg">
      <h3 className="text-xl font-semibold text-white p-4 border-b border-gray-700">
        {title}
      </h3>
      <div className="p-4">
        <p className="text-gray-300 mb-4">{statement}</p>
        <div className="space-y-3">
          {reps.map((rep, index) => (
            <div key={index} className="border border-gray-700 rounded-lg bg-gray-800/30">
              <div className="p-4">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center space-x-3">
                    <UserCircle className="w-5 h-5 text-gray-400" />
                    <span className="text-white font-medium">{rep.salesRep}</span>
                    <span className="text-sm text-gray-400">
                      ({rep.shipmentCount} shipments)
                    </span>
                  </div>
                  <button
                    onClick={() => setExpandedRep(expandedRep === index ? null : index)}
                    className="p-1 hover:bg-gray-700 rounded transition-colors"
                  >
                    {expandedRep === index ? 
                      <ChevronUp className="w-5 h-5 text-gray-400" /> : 
                      <ChevronDown className="w-5 h-5 text-gray-400" />
                    }
                  </button>
                </div>

                <div className="grid grid-cols-3 gap-4">
                  <div className="p-3 bg-gray-700/30 rounded-lg">
                    <div className="text-sm text-gray-400">Revenue</div>
                    <div className="text-lg font-semibold text-green-400">
                      {rep.totalRevenue}
                    </div>
                  </div>
                  
                  <div className="p-3 bg-gray-700/30 rounded-lg">
                    <div className="text-sm text-gray-400">Profit</div>
                    <div className="text-lg font-semibold text-blue-400">
                      {rep.profit}
                    </div>
                  </div>
                  
                  <div className="p-3 bg-gray-700/30 rounded-lg">
                    <div className="text-sm text-gray-400">Margin</div>
                    <div className="text-lg font-semibold text-yellow-400">
                      {rep.margin}
                    </div>
                  </div>
                </div>
              </div>

              {expandedRep === index && (
                <div className="px-4 pb-4 pt-2 border-t border-gray-700">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="text-sm">
                      <span className="text-gray-400">Performance Rating: </span>
                      <span className={`font-medium ${
                        parseFloat(rep.margin) >= 25 ? 'text-green-400' :
                        parseFloat(rep.margin) >= 20 ? 'text-blue-400' :
                        parseFloat(rep.margin) >= 15 ? 'text-yellow-400' :
                        'text-red-400'
                      }`}>
                        {parseFloat(rep.margin) >= 25 ? 'Excellent' :
                         parseFloat(rep.margin) >= 20 ? 'Good' :
                         parseFloat(rep.margin) >= 15 ? 'Fair' :
                         'Needs Improvement'}
                      </span>
                    </div>
                    <div className="text-sm">
                      <span className="text-gray-400">Average Revenue/Shipment: </span>
                      <span className="text-white">
                        {(parseFloat(rep.totalRevenue.replace(/[$,]/g, '')) / rep.shipmentCount)
                          .toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SalesRepAnalysis; 