import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Login from './components/Login';
import Navbar from './components/Navbar';
import SearchBar from './components/Search/SearchBar';
import LandingPage from './pages/LandingPage';
import ContactPage from './pages/ContactPage';

const App = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center">
        <div className="text-white flex flex-col items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white mb-4"></div>
          <div>Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      {isAuthenticated && <Navbar />}
      <Routes>
        <Route 
          path="/" 
          element={!isAuthenticated ? <LandingPage /> : <Navigate to="/dashboard" />} 
        />
        
        <Route 
          path="/login" 
          element={!isAuthenticated ? <Login /> : <Navigate to="/dashboard" />} 
        />
        
        <Route 
          path="/dashboard" 
          element={
            isAuthenticated ? (
              <div>
                <SearchBar />
              </div>
            ) : (
              <Navigate to="/" />
            )
          } 
        />
        
        <Route 
          path="/contact" 
          element={<ContactPage />}
        />
      </Routes>
    </div>
  );
};

export default App;