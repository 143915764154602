import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Lock, LogIn, Mail } from 'lucide-react';

const Login = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');  // Updated to redirect to dashboard
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = (connection) => {
    const authParams = {
      authorizationParams: {
        redirect_uri: `${window.location.origin}/dashboard`,  // Updated redirect URI
        scope: 'openid profile email'
      }
    };

    if (connection !== 'email') {
      authParams.authorizationParams.connection = connection;
    }

    loginWithRedirect(authParams);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center px-4">
      <div className="w-full max-w-md transform transition-all">
        <div className="bg-gray-800/50 backdrop-blur-xl rounded-2xl shadow-2xl border border-gray-700/50 p-8 space-y-8">
          <div className="text-center space-y-2">
            <div className="inline-block p-4 rounded-full bg-gray-700/50 mb-4">
              <Lock className="h-8 w-8 text-gray-300" />
            </div>
            <h1 className="text-3xl font-bold text-white tracking-tight">
              one-stop
            </h1>
            <h2 className="text-gray-400 text-lg">
              Sign in to your account
            </h2>
          </div>

          <div className="space-y-4">
            <button
              onClick={() => handleLogin('email')}
              className="w-full group relative flex items-center justify-center px-4 py-3 bg-gray-700 hover:bg-gray-600 text-white rounded-xl transition-all duration-200 ease-in-out transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 focus:ring-offset-gray-900"
            >
              <span className="absolute left-4">
                <LogIn className="h-5 w-5 text-gray-300 group-hover:text-gray-200" />
              </span>
              <span className="font-medium">Continue with Email</span>
            </button>

            <button
              onClick={() => handleLogin('google-oauth2')}
              className="w-full flex items-center justify-center px-4 py-3 bg-gray-700 hover:bg-gray-600 text-white rounded-xl transition-all duration-200 ease-in-out transform hover:scale-[1.02]"
            >
              <Mail className="h-5 w-5 mr-2" />
              <span className="font-medium">Continue with Google</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;