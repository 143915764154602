import React, { useState } from 'react';

const ContactPage = () => {
  const [formStatus, setFormStatus] = useState('idle');
  const companyTypes = ['Carrier', 'Shipper', 'Brokerage', 'Other'];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus('submitting');
    
    try {
      const response = await fetch('https://formspree.io/f/mqakpybe', {
        method: 'POST',
        body: new FormData(e.target),
        headers: {
          Accept: 'application/json',
        },
      });
      
      if (response.ok) {
        setFormStatus('success');
        e.target.reset();
      } else {
        setFormStatus('error');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setFormStatus('error');
    }
  };

  return (
    <div className="min-h-screen bg-[#1B1E23] flex flex-col">
      <nav className="p-4 bg-[#1B1E23]">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center">
            <a href="/" className="text-xl font-bold text-white">one-stop</a>
          </div>
        </div>
      </nav>
      
      <main className="flex-grow container mx-auto px-4 py-12">
        <div className="max-w-xl mx-auto">
          <h1 className="text-4xl font-bold text-white mb-2 text-center">Request a Demo</h1>
          <p className="text-gray-400 text-center mb-8">Fill out the form below to schedule a demo with us.</p>
          
          <div className="bg-[#2D3139] rounded-xl p-8 shadow-lg">
            {formStatus === 'success' ? (
              <div className="text-center py-12">
                <h3 className="text-2xl font-bold text-green-400 mb-4">Thank You!</h3>
                <p className="text-gray-300">We've received your request and will be in touch soon.</p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-5">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-300 mb-1">First name</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      required
                      className="w-full bg-[#1B1E23] border border-gray-700 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-[#4D7EF9] focus:ring-1 focus:ring-[#4D7EF9] transition-colors"
                    />
                  </div>
                  <div>
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-300 mb-1">Last name</label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      required
                      className="w-full bg-[#1B1E23] border border-gray-700 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-[#4D7EF9] focus:ring-1 focus:ring-[#4D7EF9] transition-colors"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">Email address</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    className="w-full bg-[#1B1E23] border border-gray-700 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-[#4D7EF9] focus:ring-1 focus:ring-[#4D7EF9] transition-colors"
                  />
                </div>

                <div>
                  <label htmlFor="company" className="block text-sm font-medium text-gray-300 mb-1">Company name</label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    required
                    className="w-full bg-[#1B1E23] border border-gray-700 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-[#4D7EF9] focus:ring-1 focus:ring-[#4D7EF9] transition-colors"
                  />
                </div>

                <div>
                  <label htmlFor="companyType" className="block text-sm font-medium text-gray-300 mb-1">Company type</label>
                  <select
                    id="companyType"
                    name="companyType"
                    required
                    className="w-full bg-[#1B1E23] border border-gray-700 rounded-lg px-3 py-2 text-white focus:outline-none focus:border-[#4D7EF9] focus:ring-1 focus:ring-[#4D7EF9] transition-colors"
                  >
                    <option value="" disabled selected>Select company type</option>
                    {companyTypes.map((type) => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </select>
                </div>

                <button
                  type="submit"
                  disabled={formStatus === 'submitting'}
                  className="w-full bg-[#4D7EF9] text-white px-4 py-2 rounded-lg hover:bg-[#4D7EF9]/90 transition-colors disabled:opacity-50 mt-6"
                >
                  {formStatus === 'submitting' ? 'Requesting demo...' : 'Request Demo'}
                </button>

                {formStatus === 'error' && (
                  <p className="text-red-400 text-sm text-center mt-2">Something went wrong. Please try again.</p>
                )}
              </form>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContactPage; 