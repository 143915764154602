import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  ArrowRight, Search, BarChart2, Database, 
  Calendar, Command, Globe, MessageSquare,
  Truck, Package, Network, ShoppingCart,
  FileText, Shield, Clock, Award
} from 'lucide-react';

import SearchDemo from '../components/SearchDemo';
import SystemTopology from '../components/SystemTopology';

// Feature Card Component
const FeatureCard = ({ icon: Icon, title, description, demo }) => (
  <div className="bg-[#1E2128] rounded-2xl p-6 hover:shadow-xl transition-all duration-300">
    <div className="mb-8">
      {demo}
    </div>
    <h3 className="text-2xl font-semibold text-white mb-3">{title}</h3>
    <p className="text-gray-400 leading-relaxed">{description}</p>
  </div>
);

// Command Menu Component
const CommandMenu = () => {
  const queries = [
    { icon: Search, text: "Show profitability for Chicago lanes" },
    { icon: Truck, text: "Track shipment SHIP123456" },
    { icon: BarChart2, text: "Compare Q3 vs Q2 performance" },
    { icon: Package, text: "Show delayed deliveries" },
    { icon: Globe, text: "View carrier compliance rates" },
    { icon: MessageSquare, text: "Generate monthly KPI report" }
  ];

  return (
    <div className="bg-[#151719] rounded-xl p-4">
      <div className="flex items-center space-x-2 mb-4">
        <Command className="w-4 h-4 text-blue-500" />
        <span className="text-sm text-gray-400">Quick Actions</span>
        <span className="text-xs text-gray-500 ml-auto">⌘K</span>
      </div>
      <div className="space-y-2">
        {queries.map(({ icon: Icon, text }) => (
          <div 
            key={text}
            className="flex items-center space-x-3 px-3 py-2 hover:bg-[#1E2128] rounded-lg text-gray-300 cursor-pointer group transition-colors"
          >
            <Icon className="w-4 h-4 text-gray-400 group-hover:text-blue-500 transition-colors" />
            <span className="text-sm">{text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

// Analytics Chart Component
const AnalyticsChart = () => {
  const data = [
    { label: 'Mon', value: 156, target: 140 },
    { label: 'Tue', value: 142, target: 140 },
    { label: 'Wed', value: 158, target: 140 },
    { label: 'Thu', value: 145, target: 140 },
    { label: 'Fri', value: 168, target: 140 },
    { label: 'Sat', value: 135, target: 140 },
    { label: 'Sun', value: 140, target: 140 }
  ];

  const maxValue = 200; // Maximum value for scaling

  return (
    <div className="bg-[#151719] rounded-xl p-4">
      <div className="flex items-center justify-between mb-4">
        <span className="text-sm text-gray-400">Weekly Booked Loads</span>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2 rounded-full bg-blue-500"/>
            <span className="text-xs text-gray-500">Actual</span>
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2 rounded-full bg-gray-500"/>
            <span className="text-xs text-gray-500">Target</span>
          </div>
        </div>
      </div>
      <div className="h-40 flex items-end space-x-2 relative">
        {/* Background grid lines */}
        <div className="absolute inset-0 flex flex-col justify-between">
          {[...Array(5)].map((_, i) => (
            <div 
              key={i} 
              className="w-full h-px bg-gray-800"
            />
          ))}
        </div>
        
        {data.map((item, i) => (
          <div key={i} className="relative flex-1 flex flex-col items-center group">
            {/* Target line */}
            <div 
              className="absolute w-full h-0.5 bg-gray-600"
              style={{ 
                bottom: `${(item.target / maxValue) * 100}%`,
                zIndex: 1 
              }}
            />
            
            {/* Actual value bar */}
            <div 
              className="relative w-full bg-gradient-to-t from-blue-600/80 to-blue-400/80 rounded-sm"
              style={{ 
                height: `${(item.value / maxValue) * 100}%`,
                minHeight: '2px'
              }}
            >
              {/* Tooltip */}
              <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity">
                <span className="text-xs text-white bg-gray-800 px-2 py-1 rounded">
                  {item.value}
                </span>
              </div>
            </div>
            
            <span className="text-xs text-gray-500 mt-2">{item.label}</span>
          </div>
        ))}
      </div>
      {/* Y-axis labels */}
      <div className="flex justify-between mt-2">
        <span className="text-xs text-gray-500">0</span>
        <span className="text-xs text-gray-500">{maxValue}</span>
      </div>
    </div>
  );
};

// Self-Service Portal Component
const SelfServicePortal = () => (
  <div className="bg-[#151719] rounded-xl p-6">
    <div className="space-y-4">
      <div className="flex items-center space-x-3 p-3 bg-blue-500/10 rounded-lg cursor-pointer hover:bg-blue-500/20 transition-colors">
        <Search className="w-5 h-5 text-blue-400" />
        <span className="text-gray-300">Track shipment status</span>
      </div>
      <div className="flex items-center space-x-3 p-3 bg-purple-500/10 rounded-lg cursor-pointer hover:bg-purple-500/20 transition-colors">
        <FileText className="w-5 h-5 text-purple-400" />
        <span className="text-gray-300">Download PODs & BOLs</span>
      </div>
      <div className="flex items-center space-x-3 p-3 bg-green-500/10 rounded-lg cursor-pointer hover:bg-green-500/20 transition-colors">
        <BarChart2 className="w-5 h-5 text-green-400" />
        <span className="text-gray-300">View analytics dashboard</span>
      </div>
    </div>
  </div>
);

// Features Grid Component
const FeaturesGrid = () => (
  <div className="w-full max-w-[1400px] mx-auto px-4">
    <div className="text-center mb-20">
      <h1 className="text-[56px] font-semibold text-white mb-5 leading-tight">
        Powerful Features to Transform
        <br />
        Your Logistics Operations
      </h1>
      <p className="text-gray-400 text-xl">
        Everything you need to manage freight logistics from start to finish, in one AI-powered platform.
      </p>
    </div>

    <div className="flex flex-row gap-6 justify-center">
      <FeatureCard
        icon={Search}
        title="Natural Language Intelligence"
        description="Interact with your data effortlessly. Use natural language queries to access instant insights, powered by AI."
        demo={<CommandMenu />}
      />
      
      <FeatureCard
        icon={BarChart2}
        title="Real-time Analytics"
        description="Track key metrics and generate insights with our powerful analytics engine."
        demo={<AnalyticsChart />}
      />
      
      <FeatureCard
        icon={Database}
        title="Self-Service Portal"
        description="Empower your team with instant access to shipment information and documentation."
        demo={<SelfServicePortal />}
      />
    </div>
  </div>
);

// Systems Integration Component with Interactive Network Visualization
const SystemsIntegration = () => {
  const nodes = [
    { 
      id: 'tms', 
      icon: Truck, 
      label: 'TMS',
      subLabel: 'Transport Management',
      position: 'top-10 left-1/4',
      coordinates: { x: '30%', y: '25%' }
    },
    { 
      id: 'central', 
      icon: Command, 
      label: 'One-Stop',
      subLabel: 'Central Hub',
      position: 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
      coordinates: { x: '50%', y: '50%' }
    },
    { 
      id: 'erp', 
      icon: BarChart2, 
      label: 'ERP',
      subLabel: 'Enterprise Resource Planning',
      position: 'top-10 right-1/4',
      coordinates: { x: '70%', y: '25%' }
    },
    { 
      id: 'wms', 
      icon: Package, 
      label: 'WMS',
      subLabel: 'Warehouse Management',
      position: 'bottom-20 left-1/4',
      coordinates: { x: '30%', y: '75%' }
    },
    { 
      id: 'api', 
      icon: Globe, 
      label: 'APIs',
      subLabel: 'Integration Layer',
      position: 'bottom-20 right-1/4',
      coordinates: { x: '70%', y: '75%' }
    },
    { 
      id: 'database', 
      icon: Database, 
      label: 'Data Lake',
      subLabel: 'Unified Storage',
      position: 'top-1/3 right-10',
      coordinates: { x: '85%', y: '50%' }
    }
  ];

  return (
    <section className="py-24 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-6">
            Connected Logistics Ecosystem
          </h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Seamlessly integrate your entire logistics tech stack through a unified platform
          </p>
        </div>

        <div className="relative h-[600px] bg-[#151719] rounded-2xl p-8">
          {/* Connection Lines with Flowing Dots */}
          <svg className="absolute inset-0 w-full h-full">
            <defs>
              <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#3B82F6" stopOpacity="0.1" />
                <stop offset="50%" stopColor="#3B82F6" stopOpacity="0.3" />
                <stop offset="100%" stopColor="#3B82F6" stopOpacity="0.1" />
              </linearGradient>
              
              <pattern id="flowingDots" width="30" height="10" patternUnits="userSpaceOnUse">
                <circle cx="5" cy="5" r="2" fill="#3B82F6">
                  <animate
                    attributeName="opacity"
                    values="0;1;0"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </circle>
              </pattern>
            </defs>

            {/* Connection Lines */}
            {nodes.map((node) => (
              node.id !== 'central' && (
                <g key={`connection-${node.id}`}>
                  <line
                    x1="50%" y1="50%"
                    x2={node.coordinates.x} y2={node.coordinates.y}
                    stroke="url(#lineGradient)"
                    strokeWidth="2"
                  />
                  <line
                    x1="50%" y1="50%"
                    x2={node.coordinates.x} y2={node.coordinates.y}
                    stroke="url(#flowingDots)"
                    strokeWidth="4"
                    strokeDasharray="4 4"
                  >
                    <animate
                      attributeName="stroke-dashoffset"
                      from="30"
                      to="0"
                      dur="2s"
                      repeatCount="indefinite"
                    />
                  </line>
                </g>
              )
            ))}
          </svg>

          {/* Nodes */}
          {nodes.map((node) => (
            <div
              key={node.id}
              className={`absolute ${node.position} transform group`}
            >
              <div className="relative">
                <div className="w-16 h-16 bg-[#1E2128] rounded-full flex items-center justify-center group-hover:scale-110 transition-transform">
                  <node.icon className="w-8 h-8 text-blue-400" />
                </div>
                
                <div className="absolute -right-1 -top-1 w-3 h-3">
                  <div className="w-full h-full bg-green-500 rounded-full animate-ping opacity-75" />
                  <div className="absolute inset-0 w-full h-full bg-green-500 rounded-full" />
                </div>

                <div className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 text-center">
                  <div className="text-white font-medium">{node.label}</div>
                  <div className="text-gray-400 text-sm">{node.subLabel}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Productivity Metrics Component
const ProductivityMetrics = () => {
  const metrics = [
    { 
      label: 'ROI', 
      value: '45', 
      unit: '%', 
      subtext: 'Cost reduction in operations',
      progress: 45 
    },
    { 
      label: 'Response Time', 
      value: '< 2', 
      unit: 'sec', 
      subtext: 'Average system response',
      progress: 90 
    },
    { 
      label: 'Cost Savings', 
      value: '65K', 
      unit: '$/yr', 
      subtext: 'Reduced manual processing',
      progress: 65 
    },
    { 
      label: 'Efficiency', 
      value: '3x', 
      unit: '', 
      subtext: 'Faster than manual process',
      progress: 85 
    }
  ];

  return (
    <section className="py-20 bg-[#1E2128]">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center space-x-2 bg-[#1E2128] rounded-full px-4 py-2 mb-6">
            <ArrowRight className="w-4 h-4 text-blue-500" />
            <span className="text-sm text-gray-300">Increased productivity</span>
          </div>
          <h2 className="text-4xl font-bold text-white mb-4">
            Streamlined for you
          </h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Delightfully crafted to help you stay in flow and focus on what
            you care about - leveraging your data.
          </p>
        </div>

        <div className="grid grid-cols-2 gap-8">
          {metrics.map(({ label, value, unit, subtext, progress }) => (
            <motion.div 
              key={label}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-[#151719] rounded-xl p-6 hover:bg-[#1A1D21] transition-colors"
            >
              <div className="flex justify-between items-start mb-4">
                <span className="text-sm text-gray-400">{label}</span>
                <div className="text-3xl font-bold text-white flex items-baseline">
                  {value}
                  <span className="text-sm text-gray-400 ml-1">{unit}</span>
                </div>
              </div>
              <div className="h-2 bg-gray-800 rounded-full overflow-hidden">
                <motion.div 
                  className="h-full bg-gradient-to-r from-blue-500 to-blue-400 rounded-full"
                  initial={{ width: 0 }}
                  whileInView={{ width: `${progress}%` }}
                  viewport={{ once: true }}
                  transition={{ duration: 1, ease: "easeOut" }}
                />
              </div>
              <p className="text-sm text-gray-400 mt-2">{subtext}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const CallToAction = () => {
  const navigate = useNavigate();

  return (
    <section className="relative py-32 overflow-hidden">
      <div className="max-w-6xl mx-auto px-4">
        {/* Main Content */}
        <div className="text-center">
          <h2 className="text-[64px] font-semibold leading-tight mb-8">
            <span className="text-blue-500">Building</span> the
            <br />
            Future <span className="text-blue-500">Together</span>
          </h2>

          <div className="max-w-3xl mx-auto space-y-6 mb-12">
            <p className="text-gray-400 text-xl leading-relaxed">
              While others talk about AI transformation, we've built it specifically for logistics. one-stop provides a practical, ready-to-use solution that brings the power of AI to your daily operations.
            </p>
            <p className="text-gray-400 text-xl leading-relaxed">
              No complex integration. No AI expertise needed. Just immediate insights that help you make better decisions and grow your business.
            </p>
          </div>

          <button 
            onClick={() => navigate('/contact')}
            className="group bg-[#1E2128] hover:bg-[#2D3139] text-white px-8 py-4 rounded-2xl transition-all duration-300 flex items-center justify-center mx-auto"
          >
            <span className="text-lg font-medium mr-3">Book a Demo</span>
            <span className="bg-blue-500/10 p-2 rounded-lg group-hover:bg-blue-500/20 transition-colors">
              <Calendar className="w-5 h-5 text-blue-500" />
            </span>
          </button>
        </div>

        {/* Background Elements */}
        <div className="absolute inset-0 pointer-events-none">
          {/* Gradient Orbs */}
          <div className="absolute top-20 left-20 w-96 h-96 bg-blue-500/10 rounded-full blur-3xl" />
          <div className="absolute bottom-20 right-20 w-96 h-96 bg-blue-500/10 rounded-full blur-3xl" />
          
          {/* Subtle Grid */}
          <div className="absolute inset-0 bg-[linear-gradient(to_right,#1E2128_1px,transparent_1px),linear-gradient(to_bottom,#1E2128_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_50%,#000_70%,transparent_100%)]" />
        </div>
      </div>
    </section>
  );
};

const LandingPage = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const scrollToFeatures = (e) => {
    e.preventDefault();
    const featuresSection = document.getElementById('features');
    if (featuresSection) {
      const navHeight = 64; // Height of your fixed navbar
      const elementPosition = featuresSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="min-h-screen bg-[#151719]">
      {/* Navigation */}
      <nav className="fixed w-full z-50 bg-[#151719]/80 backdrop-blur-md">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16 items-center">
            <span className="text-xl font-bold text-white">one-stop</span>
            <div className="flex items-center space-x-8">
              <a 
                href="#features" 
                onClick={scrollToFeatures}
                className="text-sm text-gray-300 hover:text-white transition-colors"
              >
                Features
              </a>
              <button 
                onClick={() => navigate('/contact')}
                className="bg-[#2D3139] text-white px-4 py-2 rounded-lg hover:bg-[#3D4251] transition-colors"
              >
                Book a Demo
              </button>
              <button 
                onClick={() => loginWithRedirect()}
                className="text-white px-4 py-2 rounded-lg hover:text-blue-400 transition-colors"
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="relative pt-32 pb-48">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-300 via-blue-500 to-blue-300 [text-shadow:0_4px_16px_rgba(59,130,246,0.5)]">
                The Future of Logistics is
              </span>
              <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-blue-100 to-white [text-shadow:0_4px_16px_rgba(255,255,255,0.2)]">
                Conversational AI
              </span>
            </h1>
            <p className="text-xl text-gray-400 max-w-2xl mx-auto mb-10">
              Track shipments, analyze performance, and access documents with just a question - no complex dashboards needed.
            </p>
          </div>

          {/* Search Demo */}
          <SearchDemo />
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-32 relative">
        {/* Add visual separator */}
        <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-blue-500/20 to-transparent" />
        
        <div className="w-full max-w-[1400px] mx-auto px-4">
          <div className="text-center mb-20">
            <h2 className="text-[48px] font-semibold text-white mb-5 leading-tight">
              Powerful Features to Transform
              <br />
              Your Logistics Operations
            </h2>
            <p className="text-gray-400 text-xl">
              Everything you need to manage freight logistics from start to finish, in one AI-powered platform.
            </p>
          </div>

          <div className="flex flex-row gap-6 justify-center">
            <FeatureCard
              icon={Search}
              title="Natural Language Intelligence"
              description="Interact with your data effortlessly. Use natural language queries to access instant insights, powered by AI."
              demo={<CommandMenu />}
            />
            
            <FeatureCard
              icon={BarChart2}
              title="Real-time Analytics"
              description="Stay ahead with dynamic metrics tracking and deep data insights to boost operational efficiency and profitability."
              demo={<AnalyticsChart />}
            />
            
            <FeatureCard
              icon={Database}
              title="Self-Service Portal"
              description="Empower your customers with instant access to shipment information and documentation."
              demo={<SelfServicePortal />}
            />
          </div>
        </div>
      </section>

      <SystemsIntegration />
      <ProductivityMetrics />
      <CallToAction />

      {/* Keep your existing footer */}
      <footer className="bg-[#1E2128] py-12">
        {/* Footer content */}
      </footer>
    </div>
  );
};

export default LandingPage;