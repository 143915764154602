import React, { useState, useEffect } from 'react';
import { Search, Command } from 'lucide-react';

const SearchDemo = () => {
  const [searchText, setSearchText] = useState('');
  const [responseText, setResponseText] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  const demoQueries = [
    { 
      query: "Show me all delayed shipments from last week", 
      response: "Found 3 delayed shipments from the past week:\n- Order #1242 (LAX → NYC) - Delayed by 2 days\n- Order #1255 (SEA → MIA) - Delayed by 1 day\n- Order #1267 (CHI → HOU) - Delayed by 3 days"
    },
    {
      query: "What's the status of order #1234?",
      response: "Order #1234 is currently in transit:\n- Departed from Los Angeles Hub (LAX)\n- Expected delivery: Oct 29, 2024\n- Current location: Denver, CO\n- On schedule"
    },
    {
      query: "Summarize this month's performance",
      response: "October 2024 Performance:\n- 94% on-time delivery rate\n- 2,450 shipments processed\n- Average transit time: 3.2 days\n- Customer satisfaction: 4.8/5"
    }
  ];

  const simulateTyping = async (text, delay = 50) => {
    setIsTyping(true);
    let currentText = '';
    for (let i = 0; i < text.length; i++) {
      currentText += text[i];
      setSearchText(currentText);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
    setIsTyping(false);
  };

  const simulateResponse = async (response) => {
    setResponseText('');
    let currentText = '';
    for (let i = 0; i < response.length; i++) {
      currentText += response[i];
      setResponseText(currentText);
      await new Promise(resolve => setTimeout(resolve, 20));
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    
    const runDemo = async () => {
      try {
        for (const demo of demoQueries) {
          if (!isSubscribed) return;
          
          await simulateTyping(demo.query);
          if (!isSubscribed) return;
          
          await new Promise(resolve => setTimeout(resolve, 500));
          if (!isSubscribed) return;
          
          await simulateResponse(demo.response);
          if (!isSubscribed) return;
          
          await new Promise(resolve => setTimeout(resolve, 2000));
          if (!isSubscribed) return;
          
          setSearchText('');
          setResponseText('');
          
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      } catch (error) {
        console.error('Demo animation error:', error);
      }
    };

    runDemo();
    
    const totalDemoTime = demoQueries.length * (3500 + 1000);
    const interval = setInterval(runDemo, totalDemoTime);
    
    return () => {
      isSubscribed = false;
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="w-full max-w-3xl mx-auto bg-[#1E2128] rounded-xl shadow-2xl overflow-hidden border border-gray-700">
      <div className="p-4">
        <div className="relative">
          <Search className="absolute left-3 top-3 text-gray-400" size={20} />
          <input
            type="text"
            value={searchText}
            readOnly
            className="w-full bg-[#2D3139] text-white pl-10 pr-4 py-2 rounded-lg border border-gray-700 focus:outline-none focus:ring-2 focus:ring-[#4D7EF9]"
            placeholder="Ask about shipments, tracking, or analysis..."
          />
          <Command className="absolute right-3 top-3 text-gray-400" size={20} />
        </div>
        <div className={`mt-4 text-gray-300 font-mono text-sm min-h-[200px] ${responseText ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
          {responseText.split('\n').map((line, index) => (
            <div key={index} className="py-1">{line}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchDemo;