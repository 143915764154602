import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

const AnalyticsChart = ({ options, loading }) => {
  if (loading) {
    return <div className="p-4 bg-gray-800/50 border border-gray-700 rounded-lg">
      <p className="text-gray-400">Loading chart...</p>
    </div>;
  }

  if (!options?.config) {
    console.warn('Invalid chart configuration:', options);
    return null;
  }

  return (
    <div className="p-4 bg-gray-800/50 border border-gray-700 rounded-lg">
      <ReactECharts
        option={options.config}
        style={{ 
          height: '400px', 
          width: '100%' 
        }}
        theme="dark"
        opts={{ renderer: 'canvas' }}
        onEvents={{
          'finished': () => console.log('Chart rendered successfully'),
          'error': (e) => console.error('Chart rendering error:', e)
        }}
      />
    </div>
  );
};

export default AnalyticsChart; 